@tailwind base;
@tailwind components;
@tailwind utilities;

  #buttons{
    padding-left: 45%;
    padding-top: 10%;
  }

  #buttons p{
    display: inline;
    border: solid black 1px;
    text-align: center;
  }


  .block-legacy{
    padding-top: 5px;
    height: 30px;
    width: 350px;
    background-color: black;
    color: white;
  }

  #box{
    position: absolute;
    text-align: center;
    border: 2px solid;
    width: 500px;
    height: 500px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    filter: drop-shadow(0px 0px);
  }

  #boxbox{
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left:-250px;
    margin-top:-250px;
  }

  @keyframes fadeout{
    100%{
      opacity: 0;
    }
    75%{
      opacity: 0.25;
    }
    50%{
      opacity: 0.50;
    }
    25%{
      opacity: 0.75;
    }
    0%{
      opacity: 1;
    }
  }

  @keyframes dothewave{
    100%{
      border-left: 2px solid rgba(0,0,0,0);
    }
    75%{
      border-bottom: 2px solid rgba(0,0,0,0);
    }
    50%{
      border-right: 2px solid rgba(0,0,0,0);
    }
    25%{
      border-top: 2px solid rgba(0,0,0,0);
    }
  }

  .wave{
    animation: 1s infinite dothewave;
  }

  .list{
    list-style-type: none;
    margin-right: 45px;
  }

  .grid-container{
    display: inline-grid;
    #border: solid black 1px;
    grid-gap: 10px 10px;
    grid-template-columns: auto auto auto;
  }

  .grid-title{
    #text-decoration: overline;
    border: solid black .5px;
    font-size: 1em;
    font-style: italic;
    padding: 3px;
  }

  .grid-item{
    padding-bottom: 2px;
  }

  ul.hlist{
    list-style-type: none;
  }

  .padright{
    padding-right: 60px;
  }

  ul.hlist li{
    display: inline;
    padding: inherit;
  }

  .item{
    padding-top: 40px;
  }

  .itemtwo{
    padding-top: 20px;
  }

  .item3{
    padding-top: 40px;
    padding-left: 40px;
    text-align: center;
  }

  .text{
    padding-top: 70px;
    padding-left: 10px;
  }

  .bottom{
    border-bottom: solid black;
  }

  .animated{
    animation-name: fadeout;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  .body-legacy{
    position: static;
    margin: 3px;
    height: 98.5%;
    width: 99%;
    border: 4px solid;
    /*background-image: url('./src/Views/legacy/bg/bg3.jpg');*/
    /*background-image: url("bg4.jpg");*/
    /*background-size: 100% 150%;*/
    /*background-size: cover;*/
    background-repeat:round;
    letter-spacing: 1px;
  }

@layer components{

  :root{
    --alien-gradient: linear-gradient(90deg, rgba(228,49,66,1) 0%, rgba(217,206,66,1) 25%, rgba(75,147,206,1) 48%, rgba(186,130,179,1) 75%, rgba(166,106,106,1) 100%);
  }

  .text-shadow{
    text-shadow: 2px 2px 2px #000000;
  }

  .image-grid{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 1rem;
    max-width: 90%;
  }

  .image-container{
    position: relative;
    /*flex-grow: 1;*/
    flex-shrink: 1;
  }

  .preview{
    /*padding: .25rem;*/

  }

  .image{
    border-radius: 1rem;
    /*height: 100%;*/
    height: 250px;
    width: auto;
    object-fit: cover;
    padding: .25rem;
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.20);
  }

  .image-overlay{
    border-radius: 1rem;
    opacity: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: black;
    transition: opacity 0.24s ease-in-out;

    max-height: 100%;

    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .image-overlay p{
    text-overflow: ellipsis;
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
  }

  .image-overlay:hover{
    opacity: 0.8;
  }

  .transition-rounded{
    transition: border-radius 0.24s ease-in-out;
  }

  .text-white-shadow{
    text-shadow: 0.1em 0.15em transparent;
  }

  .white-shadow{
    box-shadow: 5px 5px rgba(255,255,255,100);
  }

  .white-shadow-open{
    animation-name: white-shadow-open;
    animation-duration: .25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
  }

  .multi-shadow-open-br{
    animation-name: multi-shadow-open-br;
    animation-duration: .25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
  }

  .multi-shadow-open-t{
    animation-name: multi-shadow-open-t;
    animation-duration: .25s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards
  }

  .fade-in-left{
    animation-name: fade-in-left;
    animation-timing-function: ease-in-out;
    animation-duration: .15s;
    animation-fill-mode: forwards
  }

  .fade-out-left{
    animation-name: fade-in-left;
    animation-timing-function: ease-in-out;
    animation-duration: .15s;
    animation-fill-mode: forwards;
    animation-direction: reverse;
  }

  .fade-in-top{
    animation-name: fade-in-top;
    animation-timing-function: ease-in-out;
    animation-duration: .15s;
    animation-fill-mode: forwards
  }

  .height-minus-nav{
    height: calc(100vh - 6rem)
  }

  .linear-progressive-blur{
    /*-webkit-mask-image: linear-gradient(to bottom,black 10%,transparent 80%);*/
    mask-image: linear-gradient(to bottom,black 10%,transparent 80%);
    /* backdrop-filter: blur(64px); */
    /* mask: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 87.5%,
      rgba(0, 0, 0, 1) 100%
    ); */
  }

  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: red;
    transform-origin: 0%;
  }

  .dot-matrix{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    background-image: radial-gradient(
            rgba(0, 0, 0, 0) 1px, rgba(255, 255, 255, 0.4) 1px
    );
    background-size: 4px 4px;
    backdrop-filter: blur(3px);
    font-size: 14px;
    line-height: 14px;
  }

  .dot-matrix::before {
    display: block;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: black;
    opacity: 0.2;
  }

  .info-box{
    backdrop-filter: blur(10px);
    z-index: 10;
    scroll-behavior: smooth;
  }

  .horizontal-fade{
    -webkit-mask-image: -webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(2,0,0,1) 2%, rgba(0,0,0,1) 98%, rgba(20,0,0,0) 100%);
  }

  .vertical-fade{
    -webkit-mask-image: -webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(2,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(20,0,0,0) 100%);
  }

  .bottom-fade{
    -webkit-mask-image: -webkit-linear-gradient(90deg, rgba(20,0,0,0) 0%, rgba(0,0,0,1) 35%, rgba(0,0,0,1) 100%)
  }

  .line-height-md{
    line-height: 1.4 !important;
  }

  /*.tree h3{*/
  /*  font-weight: bold;*/
  /*}*/

  .tree ul{
    margin-left: 1rem;
  }

  .tree ul li{
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  body:has(.modal.show){
    overflow: hidden;
  }

  .radial-gradient{
    background: rgb(131,58,180);
    background: -moz-radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    background: radial-gradient(circle, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833ab4",endColorstr="#fcb045",GradientType=1);
  }



  .row{

  }

  .col{

  }

}



@keyframes fade-in-top{
  0% {
    opacity: 0;
    transform: translate(0%, -25%);
    filter: blur(1rem);
  }
  50%{
    opacity: 1;
    transform: translate(0%, 0%);
    filter: blur(.5rem);
  }
  100%{
    opacity: 1;
    transform: translate(0%, 0%);
    filter: blur(0);
  }
}

@keyframes fade-in-left{
  0% {
    opacity: 0;
    transform: translate(-50%, 0%)
  }
  100%{
    opacity: 1;
    transform: translate(0%, 0%)
  }
}

@keyframes multi-shadow-open-t {
  0% {
    box-shadow: 0px 0px rgba(127, 176, 105,0), 
    0px 0px rgba(255, 251, 189,0), 
    0px 0px rgba(230, 170, 104,0), 
    0px 0px rgba(202, 60, 37,0), 
    0px 0px rgba(29, 26, 5,0);
  }
  100% {
    box-shadow: 0px -5px rgba(127, 176, 105,1), 
    0px -10px rgba(255, 251, 189,1), 
    0px -15px rgba(230, 170, 104,1), 
    0px -20px rgba(202, 60, 37,1), 
    0px -25px rgba(29, 26, 5,1);
  }
}


@keyframes multi-shadow-open-br {
  0% {
    box-shadow: 0px 0px rgba(127, 176, 105,0), 
    0px 0px rgba(255, 251, 189,0), 
    0px 0px rgba(230, 170, 104,0), 
    0px 0px rgba(202, 60, 37,0), 
    0px 0px rgba(29, 26, 5,0);
  }
  100% {
    box-shadow: 5px 5px rgba(127, 176, 105,1), 
    10px 10px rgba(255, 251, 189,1), 
    15px 15px rgba(230, 170, 104,1), 
    20px 20px rgba(202, 60, 37,1), 
    25px 25px rgba(29, 26, 5,1);
  }
}

@keyframes multi-shadow-open-many {
  0% {
    box-shadow: 0px 0px rgba(127, 176, 105,0), 
    0px 0px rgba(255, 251, 189,0), 
    0px 0px rgba(230, 170, 104,0), 
    0px 0px rgba(202, 60, 37,0), 
    1px 1px rgba(29, 26, 5,0);
  }
  20% {
    box-shadow: 0px 0px rgba(127, 176, 105,0.2), 
    0px 0px rgba(255, 251, 189,0.2), 
    0px 0px rgba(230, 170, 104,0.2), 
    1px 1px rgba(202, 60, 37,0.2),
    2px 2px rgba(29, 26, 5,0.2);
  }
  40% {
    box-shadow: 0px 0px rgba(127, 176, 105,0.4), 
    0px 0px rgba(255, 251, 189,0.4), 
    1px 1px rgba(230, 170, 104,0.4), 
    2px 2px rgba(202, 60, 37,0.4), 
    3px 3px rgba(29, 26, 5,0.4);
  }
  60% {
    box-shadow: 0px 0px rgba(127, 176, 105,0.6), 
    1px 1px rgba(255, 251, 189,0.6), 
    2px 2px rgba(230, 170, 104,0.6), 
    3px 3px rgba(202, 60, 37,0.6), 
    4px 4px rgba(29, 26, 5,0.6);
  }
  80% {
    box-shadow: 1px 1px rgba(127, 176, 105,0.8), 
    2px 2px rgba(255, 251, 189,0.8), 
    3px 3px rgba(230, 170, 104,0.8), 
    4px 4px rgba(202, 60, 37,0.8), 
    5px 5px rgba(29, 26, 5,0.8);
  }
  100% {
    box-shadow: 1px 1px rgba(127, 176, 105,1), 
    2px 2px rgba(255, 251, 189,1), 
    5px 5px rgba(230, 170, 104,1), 
    10px 10px rgba(202, 60, 37,1), 
    15px 15px rgba(29, 26, 5,1);
  }
}

@keyframes white-shadow-open {
  0% {box-shadow: 0px 0px rgba(255,255,255,0);}
  25% {box-shadow: 1px 1px rgba(255,255,255,0.25);}
  50% {box-shadow: 3px 3px rgba(255,255,255,0.50);}
  100% {box-shadow: 10px 10px rgba(255,255,255,100);}
}

.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(15 23 42 / 0.6) transparent transparent transparent;
}

.curved-text{
  offset-path: path('M.5 122.7s24.7-275 276.9 0c327.1 356.7 266.1-330.3 548-33.3 256.9 270.7 271.1 0 271.1 0');
  offset-distance: calc(1.75rem);
  position: absolute;
}

.scroll-text-b {
  -moz-animation: scroll-b 15s ease-in-out alternate infinite;
  -webkit-animation: scroll-b 15s ease-in-out alternate infinite;
  animation: scroll-b 15s ease-in-out alternate infinite;
}

.scroll-text-l {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scroll-l 15s ease-in-out alternate infinite;
  -webkit-animation: scroll-l 15s ease-in-out alternate infinite;
  animation: scroll-l 15s ease-in-out alternate infinite;
}

.scroll-text-r {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scroll-r 15s ease-in-out alternate infinite;
  -webkit-animation: scroll-r 15s ease-in-out alternate infinite;
  animation: scroll-r 15s ease-in-out alternate infinite;
}

.scroll-text-shift-l{
  -moz-animation: scroll-shift-l 5s ease-in-out infinite;
  -webkit-animation: scroll-shift-l 5s ease-in-out infinite;
  animation: scroll-shift-l 5s ease-in-out infinite;
}

@keyframes scroll-shift-l {

  0%{
    opacity: 1;
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  90%{
    -moz-transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
    opacity: 1;
  }
  95%{
    opacity: 0;
  }
  100%{
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }


  /*from {*/
  /*  -moz-transform: translateX(0%);*/
  /*  -webkit-transform: translateX(0%);*/
  /*  transform: translateX(0%);*/
  /*}*/
  /*to {*/
  /*  -moz-transform: translateX(-100%);*/
  /*  -webkit-transform: translateX(-100%);*/
  /*  transform: translateX(-100%);*/
  /*}*/
}

@keyframes scroll-b {
  from {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);

  }
  to {
    -moz-transform: translateY(-55%);
    -webkit-transform: translateY(-55%);
    transform: translateY(-55%);
  }
}

@keyframes scroll-l {
  from {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes scroll-r {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.alien-gradient{
  background: rgb(228,49,66);
  background: -moz-linear-gradient(90deg, rgba(228,49,66,1) 0%, rgba(217,206,66,1) 25%, rgba(75,147,206,1) 48%, rgba(186,130,179,1) 75%, rgba(166,106,106,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(228,49,66,1) 0%, rgba(217,206,66,1) 25%, rgba(75,147,206,1) 48%, rgba(186,130,179,1) 75%, rgba(166,106,106,1) 100%);
  background: linear-gradient(90deg, rgba(228,49,66,1) 0%, rgba(217,206,66,1) 25%, rgba(75,147,206,1) 48%, rgba(186,130,179,1) 75%, rgba(166,106,106,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e43142",endColorstr="#a66a6a",GradientType=1);
}

.btn-gradient-2 {
  background: linear-gradient(white, white) padding-box,
  linear-gradient(90deg, rgba(228,49,66,1) 0%, rgba(217,206,66,1) 25%, rgba(75,147,206,1) 48%, rgba(186,130,179,1) 75%, rgba(166,106,106,1) 100%) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
}

.blob{
  height: 50px;
  width: 50px;
  background-size: cover;
  background-position: center;
  overflow: visible;
  background-repeat: repeat;
}

.blob-9{
  background-image: url("./assets/svg/SVG Shapes/shape-9.svg");
}

.blob-12{
  background-image: url("./assets/svg/SVG Shapes/shape-12.svg");
}

.blob-13{
  background-image: url("./assets/svg/SVG Shapes/shape-13.svg");
}

.blob-30{
  background-image: url("./assets/svg/SVG Shapes/shape-30.svg");
}

.blob-76{
  background-image: url("./assets/svg/SVG Shapes/shape-76.svg");
}

.blob-44{
  background-image: url("./assets/svg/SVG Shapes/shape-44.svg");
}

.animate-rotate-rock{
  animation: rotate-rock 4s ease-in-out infinite both;
}

.rotate-a{
  animation: rotateA 2s ease-in-out infinite both;
}

.rotate-b{
  animation: rotateB 5s ease-in-out infinite both;
}

.rotate-c{
  animation: rotateC 8s ease-in-out infinite both;
}

.rotate-d{
  transform-origin: bottom;
  animation: rotateD 8s ease-in-out infinite both;
}

@keyframes rotateA{
  0% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@keyframes rotateB{
  0% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes rotateC{
  0% {
    transform: rotate(16deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(16deg);
  }
}

@keyframes rotateD{
  0% {
    transform: rotate(4deg);
    scale: 1
  }
  50% {
    transform: rotate(-3deg);
    scale: 0.95
  }
  100% {
    transform: rotate(5deg);
    scale: 1
  }
}

@keyframes rotate-rock{
  0% {
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75%{
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

button > .button-download{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M2 334.5c-3.8 8.8-2 19 4.6 26l136 144c4.5 4.8 10.8 7.5 17.4 7.5s12.9-2.7 17.4-7.5l136-144c6.6-7 8.4-17.2 4.6-26s-12.5-14.5-22-14.5l-72 0 0-288c0-17.7-14.3-32-32-32L128 0C110.3 0 96 14.3 96 32l0 288-72 0c-9.6 0-18.2 5.7-22 14.5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;

  background-position: 50% -150px;
  background-size: 50% 100%;

  transition: background-position 1s ease-in-out;
}

button > .button-download:hover{
  background-position: 50% 100px;
}

button:disabled{
  opacity: 0.5;
}



.gradient-background{
  background: var(--alien-gradient);
  background-size: 400% 400%;
  animation: shifting-gradient 15s ease infinite;
}

.gradient-bgcolor{
  background-color: var(--alien-gradient);
  background-size: 400% 400%;
  animation: shifting-gradient 15s ease infinite;
}

@keyframes shifting-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.scrollbar-none::-webkit-scrollbar{
  display: none;
}

.link-background-to-r{
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 4px 100%;
  color: #000;
  text-decoration: none;
  transition: all 0.2s;
}

.link-background-to-r:hover{
  background-size: 100% 100%;
}

.link-background{
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 4px 4px;
  color: #000;
  text-decoration: none;
  transition: all 0.2s;
}

.link-background:hover{
  background-size: 4px 100%;
}

.perspective-lg{
  perspective: 800px;
}

.perspective-none{
  perspective: none;
}

::-webkit-scrollbar {
  /*width: 5px;*/
  /*margin-right: 10rem;*/
  width: 3px;
  height: 3px
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255,255,255, 0.1);
  /*border-radius: 10px;*/
  /*margin-right: 10rem;*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*background: #3b82f6;*/
  background: black;
  /*border-radius: 10px;*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background-color: black;
}

@font-face {
  font-family: "CosiTimes";
  src: url("../public/fonts/CosiTimes-Bold.ttf");
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-BlackOblique.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-LightOblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-RegularOblique.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-BoldOblique.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru';
  src: url('../public/fonts/maru/GTMaru-MediumOblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Maru Mega';
  src: url('../public/fonts/maru/GTMaruMega-Mini.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Maru Mega';
  src: url('../public/fonts/maru/GTMaruMega-Midi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Maru Mega';
  src: url('../public/fonts/maru/GTMaruMega-Maxi.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Super.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Medium-Italic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Super-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Display';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Display-Regular-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: auto;
}

@font-face {
  font-family: 'GT Sectra Fine';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Fine-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'GT Sectra Fine';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Fine-Black-Italic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'GT Sectra Fine';
  src: url('../public/fonts/sectra/GT-Sectra-LCGV-Fine-Bold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Tabi';
  src: url('../public/fonts/tabi/Tabi-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tabi';
  src: url('../public/fonts/tabi/Tabi-Super.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Wulkan Display';
  src: url('../public/fonts/wulkan/WulkanDisplay-Black.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src: url('../public/fonts/rubik/Rubik-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Aeonik TRIAL';
  src: url('../public/fonts/aeonik/AeonikTRIAL-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../public/fonts/firaCode/FiraCode-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../public/fonts/firaCode/FiraCode-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../public/fonts/firaCode/FiraCode-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../public/fonts/firaCode/FiraCode-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../public/fonts/firaCode/FiraCode-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}